<template>
  <div>
    <card-loading-transparent v-if="loading"></card-loading-transparent>
    <div v-if="items">
      <div class="ml-auto mr-auto pt-3" v-for="item in items" :key="item.id">
        <date-item :read_only="read_only" :item="item" v-if="item.kind == 'date' && item.visible == true"></date-item>
        <divider-item :item="item" v-if="item.kind == 'divider' && item.visible == true"></divider-item>
        <text-item :item="item" v-if="item.kind == 'text' && item.visible == true"></text-item>
        <text-area-item :item="item" v-if="item.kind == 'textarea' && item.visible == true"></text-area-item>
        <radio-item :item="item" v-if="item.kind == 'radio' && item.visible == true"></radio-item>
        <dropdown-item :item="item" v-if="item.kind == 'dropdown' && item.visible == true"></dropdown-item>
        <checkbox-item :item="item" v-if="item.kind == 'checkbox' && item.visible == true"></checkbox-item>
        <file-item :item="item" :read_only="read_only" v-if="item.kind == 'file' && item.visible == true"></file-item>
        <rating-item :item="item" :read_only="read_only" v-if="item.kind == 'rating' && item.visible == true"></rating-item>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import Api from '@/api/index'

export default {
  name: 'FormItems',
  components: {
    DateItem: () => import('../items/DateItem.vue'),
    DividerItem: () => import('../items/DividerItem.vue'),
    TextItem: () => import('../items/TextItem.vue'),
    TextAreaItem: () => import('../items/TextAreaItem.vue'),
    RadioItem: () => import('../items/RadioItem.vue'),
    DropdownItem: () => import('../items/DropdownItem.vue'),
    CheckboxItem: () => import('../items/CheckboxItem.vue'),
    FileItem: () => import('../items/FileItem.vue'),
    RatingItem: () => import('../items/RatingItem.vue'),
  },
  props: {
    form_id: {
      type: Number,
      default: ()=> null
    },
    ghost_rating_form_items: {
      type: Array,
      default: ()=> null,
    },
  },
  data: () => ({
    count: 0,
    items: {},
    read_only: false,
    loading: false,
  }),
  mounted() {
    if(this.ghost_rating_form_items != null){
      this.setItems(this.ghost_rating_form_items)
    }else{
      this.getItems(this.form_id)
    }
  },
  methods: {
    async getDividerItems(){
      return Object.entries(this.items).forEach(([key, value]) => {
        let item_aux = {
          index_order: value.index_order || 0,
          title: value.title,
          item_id: value.id,
          answer: ''
        }
        value.kind == 'divider' ? this.done_items_attributes.push(item_aux) : ''
      })
    },
    getItems(form_id){
      this.loading = true
      let filter = {
        visible: true,
      }
      Api.Item.indexByForm(form_id, filter).then(r=>r.data).then(d=> {
        setTimeout(()=>{
          this.setItems(d)
        }, 600)
      }).catch((err) => {
        this.addNotification({type: 'error', msg: `${err}`})
      });
    },
    setItems(items){
      this.items = items
      this.orderItems()
    },
    orderItems(){
      if(this.items){
        let aux_order = {}
        let i = 0
        this.count = 0
        for(i=0;i < this.items.length;i++){
          if(this.items[i+1]){
            if(this.items[i].index_order > this.items[i+1].index_order){
              aux_order = this.items[i+1]
              this.items[i+1] = this.items[i]
              this.items[i] = aux_order
              this.count++
            }
          }
        }
        if(this.count > 0){
          this.orderItems()
        }else{
          this.loading = false
        }
      }
    },
    ...mapActions({
      addNotification: 'Notification/add',
    })
  },
  computed: {
    ...mapState({
      done_items_attributes: state=> state.DoneForm.done_items_attributes,
    }),
  },
  watch: {
  },
}
</script>

<style scoped>

</style>